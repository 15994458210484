import Vue from 'vue'
import App from './App.vue'
import router from './router/router';


// 公共样式
import './style/global.css'


Vue.config.productionTip = false


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
