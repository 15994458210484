import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'ShouYe',
        meta: {
            title: '嘉晟科技'
        },
        component: () =>
            import ('../views/ShowYe.vue')
    },
    {
        path: '/GongXiang',
        name: 'GongXiang',
        meta: {
            title: '共享产品'
        },
        component: () =>
            import ('../views/ZhuYingYeWu/GongXiang')
    },
    {
        path: '/GuangGao',
        name: 'GuangGao',
        meta: {
            title: '线下广告发布'
        },
        component: () =>
            import ('../views/ZhuYingYeWu/GuangGao')
    },
    {
        path: '/YingXiao',
        name: 'YingXiao',
        meta: {
            title: '线上引流'
        },
        component: () =>
            import ('../views/ZhuYingYeWu/YingXiao')
    },
    {
        path: '/GongXiang',
        name: 'GongXiang',
        meta: {
            title: '共享产品'
        },
        component: () =>
            import ('../views/ZhuYingYeWu/GongXiang')
    },
    {
        path: '/AnLi',
        name: 'AnLi',
        meta: {
            title: '成功案例'
        },
        component: () =>
            import ('../views/AnLi')
    },
    {
        path: '/LianXi',
        name: 'LianXi',
        meta: {
            title: '联系我们'
        },
        component: () =>
            import ('../views/LianXi')
    },
    {
        path: '/ZhaoShang',
        name: 'ZhaoShang',
        meta: {
            title: '招商代理项目'
        },
        component: () =>
            import ('../views/ZhaoShang.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router
